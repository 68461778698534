<template>
    <div class="page-wrapper">
        <LeftSideMenu />
        <RightSideMenu />

        <main>
            <router-view />
        </main>

        <Fireflies />
    </div>
</template>

<script>
    import Vue from "vue";
    import {Component} from "vue-property-decorator";
    import Fireflies from "@/components/layout/Fireflies";
    import LeftSideMenu from "@/components/layout/LeftSideMenu";
    import RightSideMenu from "@/components/layout/RightSideMenu";

    @Component({
        components: {RightSideMenu, LeftSideMenu, Fireflies}
    })
    export default class App extends Vue {

    }
</script>

<style scoped lang="scss">
    @import "./src/assets/styles/main";

    .page-wrapper {
        @media #{$media-lg} {
            padding-bottom: 60px;
        }
    }
</style>
