




import {Component, Vue} from "vue-property-decorator";
import * as PIXI from "pixi.js";
import FireflyGlade from "../../classes/FireflyGlade";

@Component
export default class Fireflies extends Vue {
    protected pixi = new PIXI.Application({
        width: window.innerWidth,
        height: window.innerHeight,
        transparent: true
    });
    protected quantity = 50;

    async mounted() {
        this.$el.appendChild(this.pixi.view);

        const fireflyGlade = new FireflyGlade(this.quantity);
        this.pixi.stage.addChild(fireflyGlade.getContainer());

        window.addEventListener('resize', this.onResize.bind(this));

        this.pixi.ticker.add(() => {
            fireflyGlade.playFrame();
        });
    }

    onResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.pixi.renderer.resize(width, height);
    }
}
