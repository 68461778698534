






















import {Component, Vue} from "vue-property-decorator";
import IconVk from "@/components/icons/socials/IconVk.vue";
import IconInstagram from "@/components/icons/socials/IconInstagram.vue";
import IconYoutube from "@/components/icons/socials/IconYoutube.vue";
import IconMenu from "@/components/icons/common/IconMenu.vue";

@Component({
    components: {IconMenu, IconYoutube, IconInstagram, IconVk}
})
export default class RightSideMenu extends Vue {
    protected menuVisible = false;

    toggleMenu() {
        this.menuVisible = !this.menuVisible;
    }
}
