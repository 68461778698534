








import {Component, Vue} from "vue-property-decorator";
import IconLogo from "@/components/icons/common/IconLogo.vue";

@Component({
    components: {IconLogo}
})
export default class LeftSideMenu extends Vue {
    protected homeVisible = true;

    created() {
        this.homeVisible = this.$route.name !== 'Main';
    }
}
